import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { MonHoc, NhomMonHoc } from "../../app/services/auth";
import type { RootState } from "../../app/store";
import { api } from "../../app/services/auth";

type CourseRegistrationState = {
  nhomMonHocs: NhomMonHoc[];
};

const initialState: CourseRegistrationState = {
  nhomMonHocs: [],
};

const slice = createSlice({
  name: "courseRegistration",
  initialState,
  reducers: {
    setNhomMonHocs: (
      state,
      { payload }: PayloadAction<NhomMonHoc[]>
    ) => {
      state.nhomMonHocs = payload;
    },
    setIsChecked: (
      state,
      { payload: { monhoc, is_checked} }: PayloadAction<{monhoc: MonHoc, is_checked: boolean}>
    ) => {
      const n = state.nhomMonHocs.find(x => x.id === monhoc.sis_pl_dotdangky_nhommonhoc_id);
      if (!n) {
        return;
      }
      const m = n.mon_hocs.find(x => x.sis_monhoc_id === monhoc.sis_monhoc_id);
      if (m) {
        if (is_checked && n.chon_toi_da <= n.mon_hocs.filter(x => x.is_checked).length) {
          return;
        }
        m.is_checked = is_checked;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getNhomMonHoc.matchFulfilled,
      (state, { payload }) => {
        state.nhomMonHocs = payload;
      }
    )
  },
});

export const { setNhomMonHocs, setIsChecked } = slice.actions;

export default slice.reducer;

export const selectCurrentNhomMonHocs = (state: RootState) => state.courseRegistration.nhomMonHocs;
