import React from "react";
import "devextreme/data/odata/store";
import {
  MonHoc,
  NhomMonHoc,
  useGetNhomMonHocQuery,
} from "../../app/services/auth";
import { useParams } from "react-router-dom";
import { CheckBox } from "devextreme-react/check-box";
import { setIsChecked } from "../../features/auth/courseRegistrationSlice";
import { useCourseRegistration } from "../../hooks/useCourseRegistration";
import { useDispatch } from "react-redux";
import { Button } from "devextreme-react/button";
import { useRegisterCoursesMutation } from "../../app/services/auth";
import notify from "devextreme/ui/notify";

const getTotalSelectedCourses = (nhomMonHocs: NhomMonHoc[]) => {
  return nhomMonHocs.reduce((total, n) => {
    total += n.mon_hocs.filter((x) => x.is_checked).length;
    return total;
  }, 0);
};

const isCourseSelected = (nhomMonHocs: NhomMonHoc[], sis_monhoc_id: number) => {
  return nhomMonHocs.reduce((isSelected, n) => {
    if (
      n.mon_hocs.some((x) => x.is_checked && x.sis_monhoc_id === sis_monhoc_id)
    ) {
      isSelected = true;
    }

    return isSelected;
  }, false);
};

export default function CourseRegistration() {
  const dispatch = useDispatch();
  const { id, chon_toi_thieu, chon_toi_da } = useParams();
  const { refetch } = useGetNhomMonHocQuery(id!);

  const { nhomMonHocs } = useCourseRegistration();

  const onValueChanged = React.useCallback(
    (e: any, x: NhomMonHoc, y: MonHoc) => {
      dispatch(setIsChecked({ monhoc: y, is_checked: e.value }));
    },
    [dispatch]
  );

  const [registerCourses] = useRegisterCoursesMutation();

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Course Registration</h2>
      {nhomMonHocs && (
        <h5 className={"content-block"}>
          Min total selected courses: {chon_toi_thieu}
          <br />
          Max total selected courses: {chon_toi_da}
          <br />
          Total selected courses: {getTotalSelectedCourses(nhomMonHocs)}
        </h5>
      )}
      {nhomMonHocs &&
        nhomMonHocs.map((x) => (
          <div
            key={x.id}
            className={"content-block dx-card responsive-paddings"}
          >
            <h5 className={"content-block"}>
              {x.ten_nhom}&nbsp;({x.mon_hocs.filter((m) => m.is_checked).length}
              /{x.chon_toi_thieu}&nbsp;-&gt;&nbsp;
              {x.is_batbuoc ? x.mon_hocs.length : x.chon_toi_da})
            </h5>
            {x.mon_hocs &&
              x.mon_hocs.map((y) => (
                <div key={y.id}>
                  <CheckBox
                    readOnly={
                      x.is_batbuoc ||
                      (!y.is_checked &&
                        x.chon_toi_da ===
                          x.mon_hocs.filter((x) => x.is_checked).length) ||
                      (!y.is_checked &&
                        getTotalSelectedCourses(nhomMonHocs) ===
                          +chon_toi_da!) ||
                      (!y.is_checked &&
                        isCourseSelected(nhomMonHocs, y.sis_monhoc_id))
                    }
                    value={y.is_checked}
                    text={y.ten_mon_hoc}
                    iconSize="25"
                    onValueChanged={(e) => onValueChanged(e, x, y)}
                  />
                  <br />
                  <br />
                </div>
              ))}
          </div>
        ))}
      <Button
        className={"content-block"}
        text="Register"
        type="default"
        onClick={() => {
          const totalSelectedCourses = getTotalSelectedCourses(nhomMonHocs);
          if (+chon_toi_thieu! > totalSelectedCourses) {
            notify(`Min total selected courses: ${chon_toi_thieu}`, "error");
            return;
          }
          if (+chon_toi_da! < totalSelectedCourses) {
            notify(`Max total selected courses: ${chon_toi_da}`, "error");
            return;
          }
          for (let index = 0; index < nhomMonHocs.length; index++) {
            const n = nhomMonHocs[index];
            const totalSelectedCourses = n.mon_hocs.filter(
              (x) => x.is_checked
            ).length;
            if (n.chon_toi_thieu > totalSelectedCourses) {
              notify(
                `Min total selected courses in ${n.ten_nhom}: ${n.chon_toi_thieu}`,
                "error"
              );
              return;
            }
            if (n.chon_toi_da < totalSelectedCourses) {
              notify(
                `Max total selected courses in ${n.ten_nhom}: ${n.chon_toi_da}`,
                "error"
              );
              return;
            }
          }
          registerCourses({ nhomMonHocs, id })
            .unwrap()
            .then(() => {
              notify("Success", "success");
              refetch();
            });
        }}
      />
    </React.Fragment>
  );
}
