import { HomePage, RegistrationSessionsPage, ProfilePage, CourseRegistrationPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/registration-sessions',
        element: RegistrationSessionsPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/course-registration/:id/:chon_toi_thieu/:chon_toi_da',
        element: CourseRegistrationPage
    },
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
