import Form, { ButtonItem, ButtonOptions } from "devextreme-react/form";
import { useGoogleLogin } from "@react-oauth/google";
import { useLoginMutation } from "../../app/services/auth";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import notify from "devextreme/ui/notify";

import "./LoginForm.scss";

export default function LoginForm() {
  const dispatch = useDispatch();
  const [login] = useLoginMutation();

  const signIn = useGoogleLogin({
    onSuccess: (codeResponse) => {
      if (codeResponse) {
        const { access_token } = codeResponse;
        login({ access_token })
          .unwrap()
          .then((data) => {
            dispatch(setCredentials(data));
          })
          .catch(() => {
            notify("Login Failed", "error");
          });
      }
    },
    onError: (error) => {
      notify("Login Failed", "error");
    },
  });

  return (
    <form className={"login-form"}>
      <Form>
        <ButtonItem>
          <ButtonOptions
            text={"Sign in with Google"}
            width={"100%"}
            type="default"
            onClick={() => signIn()}
          />
        </ButtonItem>
      </Form>
    </form>
  );
}
