import React from "react";
import "./profile.scss";
import Form from "devextreme-react/form";
import { useAuth } from "../../hooks/useAuth";
import { useGetUserQuery } from "../../app/services/auth";

export default function Profile() {
  const { user } = useAuth();
  const { data } = useGetUserQuery();
  const student = {
    ID: data?.ma_hs,
    Name: data?.ho_ten,
    Grade: data?.ten_khoi,
    Nationality: data?.quoc_tich,
  };

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Profile</h2>

      <div className={"content-block dx-card responsive-paddings"}>
        <div className={"form-avatar"}>
          <img alt={""} src={user?.picture} />
        </div>
        <span>{user?.email_hocsinh}</span>
      </div>

      <div className={"content-block dx-card responsive-paddings"}>
        <Form
          readOnly={true}
          id={"form"}
          formData={student}
          labelLocation={"top"}
          colCountByScreen={colCountByScreen}
        />
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};
