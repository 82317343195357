import React from "react";
import "devextreme/data/odata/store";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { useGetDotDangKyQuery } from "../../app/services/auth";
import { Link } from "react-router-dom";

export default function RegistrationSessions() {
  const { data } = useGetDotDangKyQuery();

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Registration Sessions</h2>

      <DataGrid
        keyExpr={'id'}
        className={"dx-card wide-card"}
        dataSource={data as any}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        sorting={{ mode: "none" }}
      >
        {/* <Column dataField={'id'} width={90} 
        /> */}
        <Column
          dataField={"name"}
          // width={190}
          caption={"Name"}
          cellRender={(data) => {
            return (
              <Link
                to={`/course-registration/${data.data.id}/${data.data.chon_toi_thieu}/${data.data.chon_toi_da}`}
              >
                {data.data.name}
              </Link>
            );
          }}
        />
        <Column
          dataField={"tu_ngay"}
          caption={"Start Date"}
          dataType={"date"}
          format="dd/MM/yyyy"
        />
        <Column
          dataField={"den_ngay"}
          caption={"Due Date"}
          dataType={"date"}
          format="dd/MM/yyyy"
        />
        <Column
          dataField={"chon_toi_thieu"}
          caption={"Min total selected courses"}
        />
        <Column
          dataField={"chon_toi_da"}
          caption={"Max total selected courses"}
        />
        <Column
          alignment="left"
          dataField={"is_registered"}
          caption={"Status"}
          cellRender={(data) => {
            return (<>{data.data.is_registered ? 'Registered' : 'Unregistered'}</>)
          }}
        />
      </DataGrid>
    </React.Fragment>
  );
}
