import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import appInfo from "../../app-info";

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
// import { Mutex } from 'async-mutex'
import { logout } from "../../features/auth/authSlice";

export interface User {
  ts_hocsinh_id: number;
  dm_khoi_id: number;
  email_hocsinh: string;
  picture: string;
}

export interface UserResponse {
  user: User;
  token: string;
}

export interface LoginRequest {
  access_token: string;
}

export interface HocSinhProfile {
  id: number;
  ma_hs: string;
  ho_ten: string;
  email_hocsinh: string;
  ten_khoi: string;
  quoc_tich: string;
}

export interface DotDangKy {
  id: number;
  name: string;
  tu_ngay: string;
  den_ngay: string;
  chon_toi_thieu: number;
  chon_toi_da: number;
  is_registered: boolean;
}

export interface NhomMonHoc {
  id: number;
  ten_nhom: string;
  is_batbuoc: boolean;
  chon_toi_thieu: number;
  chon_toi_da: number;
  mon_hocs: MonHoc[];
}

export interface MonHoc {
  id: number;
  sis_pl_dotdangky_nhommonhoc_id: number;
  sis_monhoc_id: number;
  ten_mon_hoc: string;
  sort_idx: number;
  is_checked: boolean;
}

const baseQuery = fetchBaseQuery({
  baseUrl: appInfo.api_url,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // // try to get a new token
    // const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
    // if (refreshResult.data) {
    //   // store the new token
    //   api.dispatch(tokenReceived(refreshResult.data))
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions)
    // } else {
    api.dispatch(logout());
    // }
  }
  return result;
};

export const api = createApi({
  // baseQuery: fetchBaseQuery({
  //   baseUrl: appInfo.api_url,
  //   prepareHeaders: (headers, { getState }) => {
  //     // By default, if we have a token in the store, let's use that for authenticated requests
  //     const token = (getState() as RootState).auth.token
  //     if (token) {
  //       headers.set('authorization', `Bearer ${token}`)
  //     }
  //     return headers
  //   },
  // }),
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: "Login",
        method: "POST",
        body: credentials,
      }),
    }),
    getUser: builder.query<HocSinhProfile, void>({
      query: () => "User",
    }),
    getDotDangKy: builder.query<DotDangKy[], void>({
      query: () => "User/dot-dang-ky",
    }),
    getNhomMonHoc: builder.query<NhomMonHoc[], string>({
      query: (id) => `User/dot-dang-ky/${id}`,
    }),
    registerCourses: builder.mutation<
      void,
      { nhomMonHocs: NhomMonHoc[]; id: any }
    >({
      query: ({ nhomMonHocs, id }) => ({
        url: `User/dot-dang-ky/${id}`,
        method: "POST",
        body: nhomMonHocs,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetUserQuery,
  useGetDotDangKyQuery,
  useGetNhomMonHocQuery,
  useRegisterCoursesMutation,
} = api;
